(function () {
  'use strict';

  class ImageUpload {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, callback) {
      const vm = this;
      vm.$mdDialog.show({
        controller: 'ImageUploadCtrl',
        controllerAs: 'imageUpload',
        templateUrl: 'components/image-upload/image-upload.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
  }

  /**
   * @ngdoc service
   * @name components.service:ImageUpload
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('ImageUpload', ImageUpload);
}());
